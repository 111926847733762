import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StopModel } from '../models/stop';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class StopService {

  constructor(private configurationService: ConfigurationService,
    private httpClient: HttpClient) { }

  readonly baseURL = this.configurationService.api_uri + "/shuttle-route-api/stop";

  list: StopModel[] = [];

  getAll() {
    return this.httpClient.get(`${this.baseURL}`);
  }

  getByRoute(clientId:number,routeId:number) {
    return this.httpClient.get(`${this.baseURL}/route/${routeId}`);
  }

  getById(id:number) {
    return this.httpClient.get(`${this.baseURL}/id/${id}`);
  }

  refreshList() {
    this.getAll()
      .toPromise()
      .then(res => this.list = res as StopModel[]);
  }

  post(data: StopModel,routeId:number) {
    let newData = {
      name:data.name,
      description:data.description,
      routeId: routeId
    };
    console.log(newData)
    return this.httpClient.post(`${this.baseURL}`, newData);
  }

  put(data: StopModel) {
    return this.httpClient.put(`${this.baseURL}/${data.id}`, data);
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

}
