import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteModel } from '../models/route';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private configurationService: ConfigurationService,
    private httpClient: HttpClient) { }

  readonly baseURL = this.configurationService.api_uri + "/shuttle-route-api/route";

  list: RouteModel[] = [];

  getAll() {
    return this.httpClient.get(`${this.baseURL}`);
  }

  getById(id:number,clientId:number) {
    return this.httpClient.get(`${this.baseURL}/id/${id}`);
  }

  refreshList() {
    this.getAll()
      .toPromise()
      .then(res => this.list = res as RouteModel[]);
  }

  ///route/create
  post(data: RouteModel) {
    let newData = {
      name:data.name,
      description:data.description
    };
    return this.httpClient.post(`${this.configurationService.api_uri}/route/create`, newData);
  }

  put(data: RouteModel) {
    return this.httpClient.put(`${this.baseURL}/${data.id}`, data);
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

}
